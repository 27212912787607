/*
 * @Author: minihorse
 * @Date: 2020-12-09 15:30:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-24 10:00:40
 * 
 */
const Layout = () => import('../pages/layout/index')
const staticRoutes = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '商机在线'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/index')
            },
            {
                path: 'mine',
                meta: {
                    title: '我的商机'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/mine')
            },
            {
                path: 'shangjidetail/:id',
                meta: {
                    title: '商机详情'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/shangjidetail')
            },
            {
                path: 'detail/:id',
                meta: {
                    title: '商机详情'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/detail')
            },
        ]
    }, {
        path: '/personal',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '个人中心'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/personal/index')
            },
            {
                path: 'notify',
                meta: {
                    title: '通知设置'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/personal/notify')
            },
            {
                path: 'record',
                meta: {
                    title: '消费记录'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/personal/record')
            },
            {
                path: 'pay/:id',
                meta: {
                    title: '会员充值'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/personal/pay')
            },
            {
                path: 'reservation',
                meta: {
                    title: '定制商机'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/personal/reservation')
            },
            {
                path: 'member',
                meta: {
                    title: '购买会员'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/personal/member')
            },
        ]
    },
    /* 本段代码放在最后，未设置路由的地址跳转404 */
    {
        path: '*',
        redirect: '/404'
    },
    {
        path: '/404',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '页面不存在'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/404')
            },
        ]
    },
    /** 本段代码放在最后，未设置路由的地址跳转404 */
]
export default staticRoutes