<template>
  <div id="app">
    <router-view style="width: 100%;height: 100%;" :key="$route.path"></router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "app",
  methods: {
    ...mapActions({}),
  },
  created() {},
};
</script>

<style>
.ql-indent-1 {
  padding-left: 0px !important;
  text-indent: 1em !important;
}

.ql-indent-2 {
  padding-left: 0px !important;
  text-indent: 2em !important;
}

.ql-indent-3 {
  padding-left: 0px !important;
  text-indent: 3em !important;
}

.ql-indent-4 {
  padding-left: 0px !important;
  text-indent: 4em !important;
}

.ql-indent-5 {
  padding-left: 0px !important;
  text-indent: 5em !important;
}

.ql-indent-6 {
  padding-left: 0px !important;
  text-indent: 6em !important;
}

.ql-indent-7 {
  padding-left: 0px !important;
  text-indent: 7em !important;
}

.ql-indent-8 {
  padding-left: 0px !important;
  text-indent: 8em !important;
}
.ql-editor ol, .ql-editor ul {
    padding-left: 2em;
}
</style>
